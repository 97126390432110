<template>
  <footer>
    <div class="container">
      <section class="info">
        <div class="product">
          <div class="tit">产品</div>
          <div class="item" @click="toDemo('0')">婚礼版</div>
          <div class="item" @click="toDemo('1')">年会版</div>
          <div class="item" @click="toDemo('2')">生日版</div>
        </div>
        <div class="service">
          <div class="tit">服务与支持</div>
          <div class="item">
            <!-- <router-link to="/statement" target="_blank">法律声明</router-link> -->
             <a href="/statement" target="_blank">法律声明</a>
          </div>
          <div class="item">
            <!-- <router-link to="partner" target="_blank">嗨喵合伙人</router-link> -->
             <a href="/partner" target="_blank">嗨喵合伙人</a>
          </div>
          <div class="item">
            <!-- <router-link to="join" target="_blank">加入我们</router-link> -->
             <a href="/join" target="_blank">加入我们</a>
          </div>
          <div class="item">
            <!-- <router-link to="advertisement" target="_blank"
              >广告投放</router-link
            > -->
             <a href="/advertisement" target="_blank">广告投放</a>
          </div>
        </div>
        <div class="company">
          <div class="tit">嗨喵悦动</div>
          <div class="item">
            <!-- <router-link to="company" target="_blank">公司简介</router-link> -->
             <a href="/company" target="_blank">公司简介</a>
          </div>
          <div class="item">
            <!-- <router-link to="service" target="_blank">用户协议</router-link> -->
             <a href="/service" target="_blank">用户协议</a>
          </div>
          <div class="item">
            <!-- <router-link to="contact" target="_blank">联系我们</router-link> -->
             <a href="/contact" target="_blank">联系我们</a>
          </div>
          <div class="item">
            <!-- <router-link to="introduce" target="_blank">产品介绍</router-link> -->
             <a href="/introduce" target="_blank">产品介绍</a>
          </div>
          <div class="item">
            <!-- <router-link to="us" target="_blank">关于我们</router-link> -->
             <a href="/us" target="_blank">关于我们</a>
          </div>

        </div>
        <div class="contact">
          <div class="tit">联系我们</div>
          <div class="item">客服电话：19512368757 (00:00-24:00)</div>
        </div>
      </section>
      <div class="imgBox">
        <img src="@/assets/image/logo.png" alt="嗨喵悦动,嗨喵互动,logo图标" srcset="">
      </div>
      <section class="copyRight">
        <div class="cp">
          版权：©2015 hudongmiao.com . All rights reserved 嗨喵悦动
        </div>
        <div class="ICP" style="width: 450px; margin: 0 auto; padding: 15px 0">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo/"
            style="
              display: inline-block;
              text-decoration: none;
              height: 15px;
              line-height: 15px;
            "
          >
            <img src="@/assets/image/jx.png" style="float: left" alt="嗨喵悦动,嗨喵互动,logo图标"/>
            <p
              style="
                float: left;
                height: 15px;
                line-height: 15px;
                margin: 0px 0px 0px 3.75px;
                color: #939393;
              "
            >
              沪公网安备 31010702006802号
            </p>
          </a>
          <a
            target="_blank"
            href="https://beian.miit.gov.cn/"
            style="
              display: inline-block;
              text-decoration: none;
              height: 15px;
              line-height: 15px;
              margin-left: 15px;
            "
          >
            <p
              style="
                float: left;
                height: 15px;
                line-height: 15px;
                margin: 0px 0px 0px 3.75px;
                color: #939393;
              "
            >
              沪ICP备 2020028729号
            </p>
          </a>
        </div>
      </section>
    </div>
  </footer>
</template>

<script>
import { WEDDING, ENTERPRISE, BIRTH } from '@/assets/constant/default';

export default {
  name: 'myFooter',
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    toDemo(indexStr) {
      if (indexStr === '0') {
        window.open(WEDDING, '_blank');
      } else if (indexStr === '1') {
        window.open(ENTERPRISE, '_blank');
      } else if (indexStr === '2') {
        window.open(BIRTH, '_blank');
      }
    },
  },
};
</script>
<style lang="less" scoped>
footer {
  a {
    color: #83838b;
    &:hover {
      color: #fc9632;
    }
  }
  .imgBox {
    margin: 0 auto;
    margin-top: 15px;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .info {
    display: flex;
    // width: 1140px;
    padding: 30px 150px;
    background-color: #FAF9F8;
    justify-content: center;
    // justify-content: space-between;
    ;
    
    >* {
      margin: 0 auto;
    }
    .product {
      
      .tit {
        font-size: 15.75px;
        font-weight: 600;
        color: #3b3b49;
        margin-bottom: 11.25px;
      }
      .item {
        font-size: 15.75px;
        font-weight: 400;
        margin-top: 6.75px;
        color: #83838b;
        cursor: pointer;
        &:hover {
          color: #fc9632;
        }
      }
    }
    .service {
      // margin-right: 90px;
      .tit {
        font-size: 15.75px;
        font-weight: 600;
        color: #3b3b49;
        margin-bottom: 11.25px;
      }
      .item {
        font-size: 15.75px;
        font-weight: 400;
        margin-top: 6.75px;
        color: #83838b;
        cursor: pointer;
        &:hover {
          color: #fc9632;
        }
      }
    }
    .company {
      // margin-right: 90px;
      .tit {
        font-size: 15.75px;
        font-weight: 600;
        color: #3b3b49;
        margin-bottom: 11.25px;
      }
      .item {
        font-size: 15.75px;
        font-weight: 400;
        margin-top: 6.75px;
        color: #83838b;
        cursor: pointer;
        &:hover {
          color: #fc9632;
        }
      }
    }
    .contact {
      .tit {
        font-size: 15.75px;
        font-weight: 600;
        color: #3b3b49;
        margin-bottom: 11.25px;
      }
      .item {
        font-size: 15.75px;
        font-weight: 400;
        margin-top: 6.75px;
        color: #83838b;
        cursor: pointer;
      }
    }
  }
  .copyRight {

    // background: rgba(250,249,248,1);
    margin-top: 22.5px;
    .cp {
      font-size: 12px;
      font-weight: 500;
      color: #008DFF;
      text-align: center;
    }
    .ICP {
      text-align: center;
    }
  }
}
</style>
