export const API_HOST = 'https://www.hudongmiao.com/';// 接口域名
export const STATIC_HOST = 'https://static.hudongmiao.com/joymewGuanwang/';// 静态资源域名
export const HEADIMG = 'https://ustatic.hudongmiao.com/emcee/common/b51d744c7b3f4f379d3f7f989c295daf'; // 默认头像
export const WEDDING = 'https://screen.hudongmiao.com/#/?liveId=7b249edb6f5b420c82b31f485080b1a7&screenType=wedding';
export const WEDDING_ZSHL = 'https://screen.hudongmiao.com/#/?liveId=c7b1e1c6f32a4d038699bc688a60a6c2';
export const ENTERPRISE = 'https://screen.hudongmiao.com/#/?liveId=d6ce3429edd04a8aa51a4dea1be6348b&screenType=enterprise';
export const BIRTH = 'https://screen.hudongmiao.com/#/?liveId=90dee1f7763749749c19815c91108560';
export const MACDESK = 'https://ustatic.joymew.com/joymewDesk/release/Hudongmiao-0.0.0.dmg';
export const WINDOWDESK = 'https://ustatic.hudongmiao.com/joymewDesk/joymewDesk.exe';
export const SCHOOL = 'https://screen.hudongmiao.com/#/?liveId=f29aef6a4e8d432cbdbb5326f85597bf';

export const BABY = 'https://screen.hudongmiao.com/#/?liveId=d7ab02019f36404baed48145171fb93e';
export const OLDBIRTHDAY = 'https://screen.hudongmiao.com/#/?liveId=7921accf5ce242039dfc5b5ca2e4cc32';
export const AGE_CEREMONY = 'https://screen.hudongmiao.com/#/?liveId=4ddd46ba20c04885a6ee5058a5ea42f7';
export const BLY = 'https://screen.hudongmiao.com/#/?liveId=011ce8cccdaa4132a25cd9264895b5c2';
export const MYY = 'https://screen.hudongmiao.com/#/?liveId=1628cc6e935846e58fbf170f0d72d45d';

export const GRADUATION = 'https://screen.hudongmiao.com/#/?liveId=688f56dc4be84a57be45c1648590bb35';
export const THANKS = 'https://screen.hudongmiao.com/#/?liveId=3830c1957bb94b009d945687b3a85768';
export const NAME_ONBORAD = 'https://screen.hudongmiao.com/#/?liveId=a780c85a90d84534ab15f958730b0368';
export const NORMAL_VERSION = 'https://screen.hudongmiao.com/#/?liveId=90dee1f7763749749c19815c91108560';

export const PATNER = '/hm-customer/index.html#/partnerSystem/login';
